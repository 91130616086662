import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  ActionMenuSelected,
  MenuSelectedEnum,
} from "../../types/VacancyTypes.ts";
import { Vacancy } from "../../models/Vacancy.ts";

const initialState = {
  menuSelected: MenuSelectedEnum.all,
  total: 0,
  vacantes: [] as Vacancy[],
  page: 0,
  limit: 10,
  loading: true,
  param: "",
  fetch: false,
  filters: {
    type: "all",
    param: "",
    active: "all",
  },
  query: "",
  dataToRemove: null,
  dataToRemovePriority: null,
  showModal: false,
  showModalEdit: false,
  showModalPriority: false,
  showModalPri: false,
  sortBy: "id",
  defaultSort: "desc",
  priorityRemove: null,
};

const vacantesSlice = createSlice({
  name: "vacantes",
  initialState,
  reducers: {
    setMenu: (state, action: ActionMenuSelected) => {
      state.menuSelected = action.payload;
      state.page = 0;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setVacantes: (state, action: PayloadAction<Vacancy[]>) => {
      state.vacantes = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setParam: (state, action: PayloadAction<string>) => {
      state.param = action.payload;
      state.page = 0;
    },
    setFetch: (state, action: PayloadAction<boolean>) => {
      state.fetch = !state.fetch;
    },
    setFilters: (state, action: PayloadAction<any>) => {
      state.filters = action.payload;
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
      state.page = 0;
    },
    resetState: (state) => {
      Object.assign(state, initialState);
    },
    setDataToRemove: (state, action: PayloadAction<any>) => {
      state.dataToRemove = action.payload;
    },
    setPriorityRemove: (state, action: PayloadAction<any>) => {
      state.priorityRemove = action.payload;
    },
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
    setShowModalEdit: (state, action: PayloadAction<boolean>) => {
      state.showModalEdit = action.payload;
    },
    setSortBy: (state, action: PayloadAction<string>) => {
      state.sortBy = action.payload;
    },
    setDefaultSort: (state, action: PayloadAction<string>) => {
      state.defaultSort = action.payload;
    },
    setShowModalPriority: (state, action: PayloadAction<boolean>) => {
      state.showModalPriority = action.payload;
    },
    setShowModalPri: (state, action: PayloadAction<boolean>) => {
      state.showModalPri = action.payload;
    },
    setDataToRemovePriority: (state, action: PayloadAction<any>) => {
      state.dataToRemovePriority = action.payload;
    }
  },
});

export const {
  setMenu,
  setPage,
  setLimit,
  setVacantes,
  setLoading,
  setTotal,
  setParam,
  setFetch,
  setQuery,
  setFilters,
  resetState,
  setDataToRemove,
  setShowModal,
  setShowModalEdit,
  setSortBy,
  setDefaultSort,
  setPriorityRemove,
  setShowModalPriority,
  setShowModalPri,
  setDataToRemovePriority,
} = vacantesSlice.actions;

export default vacantesSlice.reducer;
