import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/es";
import { RootState } from "../../../../store";
import EmptyImage from "../../../../assets/img/ic_content.svg";

moment.locale("es");

const TableChequeo = () => {

  const { records } = useSelector((state: RootState) => state.chequeo);

  return (
    <div className="table-responsive mb-3">
      <table className="table table-hover table-panel text-nowrap align-middle mb-0">
        <thead>
          <tr>
            <th>Id</th>
            <th>Colaborador</th>
            <th>Sucursal asignada</th>
            <th>Turno</th>
            <th>Puesto</th>
            <th>Sucursal de checado</th>
            <th>Fecha</th>
            <th>Entrada</th>
            <th>Salida</th>
          </tr>
        </thead>
        <tbody>
          {records && records.length > 0 && records?.filter((record) => record.status !== "Salida").length > 0 ? (
            records
            .filter((record) => record.status !== "Salida") 
            .map((item, index) => {
              const person = item.partner.person
              const salida = records.find((record) => record.status === "Salida" && record.checkDate === item.checkDate && record.partner.id === item.partner.id)
              return (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{`${person.firstName} ${person.lastName} ${person.secondLastName}`}</td>
                  <td>{item.partner.candidate.branch.name}</td>
                  <td>{item.partner.candidate.shift.name}</td>
                  <td>{item.partner.candidate.vacancy.position.name}</td>
                  <td>{item.branch.name}</td>
                  <td>{moment(item.checkDate).format("DD/MM/YYYY")}</td>
                  <td>{item.checkTime}</td>
                  <td>{salida ? salida.checkTime : ""}</td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={10}>
                <div className='py-4'>
                  <div className="d-flex">
                    <img src={EmptyImage} alt="" className='mx-auto w-250px' />
                  </div>
                  <h5 className='text-secondary text-center fs-20px'>Aún no existen datos</h5>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableChequeo;
