import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  ActionMenuPuestosSelected,
  MenuSelectedPuestosEnum,
} from "../../types/PuestosTypes.ts";
import { Position } from "../../models/Position.ts";

const initialState = {
  menuSelected: MenuSelectedPuestosEnum.razonsocial,
  showModalRazonSocial: false,
  showModalSucursal: false,
  showModalPosition: false,
  total: 0,
  puestos: [] as Position[],
  page: 0,
  limit: 10,
  loading: true,
  param: "",
  query: "",
  fetch: false,
  showModal: false,
  currentRecord: {
    id: -1,
    name: "",
  },
  deleteModal: false,
  reactiveModal: false,
  softDeleteModal: false,
};

const puestosSlice = createSlice({
  name: "puestos",
  initialState,
  reducers: {
    setMenu: (state, action: ActionMenuPuestosSelected) => {
      state.menuSelected = action.payload;
      state.page = 0;
    },
    setShowModalRazonSocial: (state, action: PayloadAction<boolean>) => {
      state.showModalRazonSocial = action.payload;
    },
    setShowModalSucursal: (state, action: PayloadAction<boolean>) => {
      state.showModalSucursal = action.payload;
    },
    setShowModalPosition: (state, action: PayloadAction<boolean>) => {
      state.showModalPosition = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setPuestos: (state, action: PayloadAction<Position[]>) => {
      state.puestos = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setTotal: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    setParam: (state, action: PayloadAction<string>) => {
      state.param = action.payload;
      state.page = 0;
    },
    setFetch: (state, action: PayloadAction<boolean>) => {
      state.fetch = !state.fetch;
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
      state.page = 0;
    },
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
    setCurrentRecord: (
      state,
      action: PayloadAction<{ id: number; name: string }>
    ) => {
      state.currentRecord = action.payload;
    },
    resetState: (state) => {
      Object.assign(state, initialState);
    },
    setShowDeleteModal: (state, action: PayloadAction<boolean>) => {
      state.deleteModal = action.payload;
    },
    setShowReactiveModal: (state, action: PayloadAction<boolean>) => {
      state.reactiveModal = action.payload;
    },
    setShowSoftDeleteModal: (state, action: PayloadAction<boolean>) => {
      state.softDeleteModal = action.payload;
    }
  },
});

export const {
  setMenu,
  setPage,
  setLimit,
  setPuestos,
  setLoading,
  setTotal,
  setParam,
  setFetch,
  setQuery,
  setShowModal,
  setCurrentRecord,
  setShowModalRazonSocial,
  setShowModalSucursal,
  setShowModalPosition,
  resetState,
  setShowDeleteModal,
  setShowReactiveModal,
  setShowSoftDeleteModal,
} = puestosSlice.actions;

export default puestosSlice.reducer;
