import moment from "moment";

const isNumString = (str) => !isNaN(Number(str));

function parseObject(obj) {
  return Object.keys(obj).reduce((result, key) => {
    const val = obj[key];
    result[key] = isNumString(val) ? val : deepParseJson(val);
    return result;
  }, {});
}

export function deepParseJson(jsonString) {
  if (typeof jsonString === "string") {
    if (isNumString(jsonString)) {
      return jsonString;
    }
    try {
      return deepParseJson(JSON.parse(jsonString));
    } catch (err) {
      return jsonString;
    }
  } else if (Array.isArray(jsonString)) {
    return jsonString.map(deepParseJson);
  } else if (typeof jsonString === "object" && jsonString !== null) {
    return parseObject(jsonString);
  } else {
    return jsonString;
  }
}

export const getCurrencyFormat = (value: string | number) => {
  return new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  }).format(typeof value === "string" ? parseInt(value) : value);
};

export const valiteCURP = (curp: string) => {
  curp = curp?.toUpperCase().trim();

  const regex = /^[A-Z]{4}\d{6}[HM]{1}[A-Z]{5}[A-Z0-9]{2}$/;
  return regex.test(curp);
};

export const formatDate = (date: string = new Date().toDateString()) => {
  return moment(date).format("dddd, D [de] MMMM [de] YYYY");
};

export interface IDefaultArray {
  id: number;
  name: string;
}

export const excelDateToJSDate = (serial: number) => {
  const utcDays = Math.floor(serial - 25569);
  const utcValue = utcDays * 86400;
  const dateInfo = new Date(utcValue * 1000);
  return new Date(dateInfo);
};

export const sortByName = (array: any[]) => {
  return array.sort((a, b) => a.name.localeCompare(b.name));
};

export const sortByPosition = (array: any[]) => {
  return array.sort((a, b) => a?.position?.name.localeCompare(b.name));
};

export function groupAndSortPositions(positions) {
  const groupedPositions = {};

  // Agrupar posiciones por nombre
  positions.forEach(position => {
    const positionName = position.position.name;
    if (!groupedPositions[positionName]) {
      groupedPositions[positionName] = {
        position: position.position,
        shifts: []
      };
    }
    groupedPositions[positionName].shifts.push({
      shift: position.shift,
      numberOfWorkers: position.numberOfWorkers,
      requieredWorkers: position.requieredWorkers,
      partners: position.partners
    });
  });

  const result = Object.values(groupedPositions);

  return result;
}