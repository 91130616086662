import React from "react";

import { toCamelCase } from "../../../hooks/toCameCase.tsx";
import { Partners } from "../../../models/LeaderTemplate";
import Divider from "./Divider.tsx";

const RowPartners = ({ data, title }: { data: Partners[]; title: string }) => {
  return (
    <div className="row">
      <div className="col-md-4 d-flex align-items-center">
        <p className="card-text">
          <strong>{title}:</strong>
        </p>
      </div>
      <div className="col-md-8">
        {data.length === 0 && (
          <div className="col">
            <p className="card-text">Sin {title.toLowerCase()} asignado</p>
          </div>
        )}
        {data.map(({ candidate, ...partner }, index) => {
          const { person } = candidate;
          return (
            <React.Fragment key={partner.id}>
              <div className="col">
                <p className="card-text">
                  {toCamelCase(
                    `${person.firstName} ${person.lastName} ${person.secondLastName}`
                  )}{" "}
                  - {person.phone}
                </p>
              </div>
              {data.length === index + 1 ? null : <Divider />}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default RowPartners;
