import React from "react";
import FilterPuestos from "../../../components/puestos/FiltersPuestos.tsx";
import TablePuestos from "../../../components/puestos/TablePuestos.tsx";
import PaginationPuestos from "../../../components/puestos/PaginationPuestos.tsx";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/index.ts";
import {
  resetState,
  setLoading,
  setPuestos,
  setShowDeleteModal,
  setShowModalPosition,
  setShowModalRazonSocial,
  setShowModalSucursal,
  setShowReactiveModal,
  setShowSoftDeleteModal,
  setTotal,
} from "../../../store/slices/puestosSlice.ts";
import { notification } from "../../../utils/Notifications.tsx";
import { paginatePuestos } from "../../../services/position.service.ts";
import ModalContainer from "../../../components/ModalContainer.tsx";
import NewRecordPuestos from "../../../components/puestos/NewRecordPuestos.tsx";
import DeleteRecordPuestos from "../../../components/puestos/DeleteRecordPuestos.tsx";
import NewRecordPosition from "../../../components/positions/NewRecordPosition.tsx";
import NewRecordBranch from "../../../components/branch/NewRecordBranch.tsx";
import FilterPlantillas from "../../../components/plantilla/FilterPlantilla.tsx";
import TablePlantilla from "../../../components/plantilla/TablePlantilla.tsx";
import ReactivateRecordPuestos from "../../../components/puestos/ReactivateRecordPuestos.tsx";
import SoftDeleteRecordPuestos from "../../../components/puestos/SoftDeleteRecordPuestos.tsx";

type Props = {};

const PuestosComponent = (props: Props) => {
  const dispatch = useDispatch();
  const {
    menuSelected,
    page,
    limit,
    param,
    fetch,
    query,
    deleteModal,
    reactiveModal,
    softDeleteModal,
  } = useSelector((state: RootState) => state.puestos);

  const { showModalRazonSocial, showModalSucursal, showModalPosition } =
    useSelector((state: RootState) => state.puestos);

  React.useEffect(() => {
    fetchPuestos();
  }, [page, limit, menuSelected, param, fetch, query]);

  const fetchPuestos = async () => {
    dispatch(setLoading(true));
    try {
      const offset = page === 0 ? 0 : page * limit;
      let queryToSend = query + `&limit=${limit}&offset=${offset}`;
      if (queryToSend.length === 0) {
        queryToSend = "type=all";
      }
      const response = await paginatePuestos(queryToSend);
      dispatch(setPuestos(response.positions));
      dispatch(setTotal(response.total));
    } catch (error) {
      notification("Error", error.message, "danger");
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <>
      <div className="tab-pane fade active show" id="Puestos">
        <div className="card border-0 m-4">
          <div className="tab-content p-3">
            <FilterPuestos />
            <TablePuestos />
            <PaginationPuestos />
          </div>
        </div>
      </div>

      {showModalRazonSocial && (
        <ModalContainer
          open={showModalRazonSocial}
          title="Agregar Razón Social"
          closeFn={() => dispatch(setShowModalRazonSocial(false))}
          children={
            <NewRecordPuestos
              initialValues={null}
              onclose={() => dispatch(setShowModalRazonSocial(false))}
            />
          }
        />
      )}
      {showModalSucursal && (
        <ModalContainer
          open={showModalSucursal}
          title="Agregar Sucursal"
          closeFn={() => dispatch(setShowModalSucursal(false))}
          children={<NewRecordBranch />}
        />
      )}
      <ModalContainer
        open={showModalPosition}
        title="Agregar Puesto"
        closeFn={() => dispatch(setShowModalPosition(false))}
        children={
          <NewRecordPosition
            onclose={() => dispatch(setShowModalPosition(false))}
            initialValues={null}
          />
        }
      />

      <ModalContainer
        open={deleteModal}
        title="Desactivar puesto"
        closeFn={() => dispatch(setShowDeleteModal(false))}
        children={
          <DeleteRecordPuestos
            closeFn={() => dispatch(setShowDeleteModal(false))}
          />
        }
      />
      <ModalContainer
        open={reactiveModal}
        title="Reactivar puesto"
        closeFn={() => dispatch(setShowReactiveModal(false))}
        children={
          <ReactivateRecordPuestos
            closeFn={() => dispatch(setShowReactiveModal(false))}
          />
        }
      />
      <ModalContainer
        open={softDeleteModal}
        title="Eliminar puesto"
        closeFn={() => dispatch(setShowSoftDeleteModal(false))}
        children={
          <SoftDeleteRecordPuestos
            closeFn={() => dispatch(setShowSoftDeleteModal(false))}
          />
        }
      />
    </>
  );
};

export default PuestosComponent;
