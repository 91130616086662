import React from 'react'
import { readHistoryByBranch } from '../../../services/branchSchedule.service.ts'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import moment from 'moment';

const History = ({ branchId }) => {
  const [history, setHistory] = React.useState<any>([])

  const fetchHistory = async () => {
    const response = await readHistoryByBranch(branchId)

    const groupedHistory = response.reduce((acc, schedule) => {
      const date = schedule.createdAt.split('T')[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(schedule);
      return acc;
    }, {});

    const transformedHistory = Object.keys(groupedHistory).map((date) => ({
      date,
      content: Object.values(
        groupedHistory[date].reduce((acc, schedule) => {
          const timestamp = schedule.timestamp;
          if (!acc[timestamp]) {
            acc[timestamp] = { timestamp, content: [] };
          }
          acc[timestamp].content.push(schedule);
          return acc;
        }, {})
      )
    }));

    setHistory(transformedHistory)
  }

  React.useEffect(() => {
    fetchHistory()
  }, [])

  return (
    <div className="px-2">
      <h3 className="mb-3">Historial de cambios en el horario</h3>
      {
        history.map((item, index) => (
          <Accordion key={index}>
            <AccordionSummary
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography>{item.date}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {
                item.content.map((schedule, index) => (
                  <Accordion key={index}>
                    <AccordionSummary
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <Typography> {schedule.content[0].createdBy.name} {schedule.content[0].createdBy.lastName} - {moment.unix(schedule.timestamp).format('HH:mm:ss')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>
                        <b>Semana:</b> {schedule.content[0].dateRange} <br />
                        <b>Día:</b> {schedule.content[0].dayOfWeek}
                      </p>
                      <ul className="list-group">
                        {schedule.content.map((item, index) => (
                          <li key={item.id} className="list-group-item">
                            <h5 className="mb-1">{item.positionName}</h5>
                            <p className="mb-1"><strong>Turno:</strong> {item.shift}</p>
                            <p className="mb-1"><strong>Fecha Exacta:</strong> {item.exactDate}</p>
                            <p className="mb-1"><strong>Sucursal:</strong> {item.branch.name}</p>
                            <p className="mb-1"><strong>Colaborador:</strong> {item.partner.person.firstName} {item.partner.person.lastName}</p>
                            <p className="mb-1"><strong>Entrada:</strong> {item.startTime.split(' - ')[0]}</p>
                            <p className="mb-1"><strong>Salida:</strong> {item.endTime.split(' - ')[1]}</p>
                          </li>
                        ))}
                      </ul>

                    </AccordionDetails>
                  </Accordion>
                ))
              }
            </AccordionDetails>
          </Accordion>
        ))
      }
    </div>
  );
}

export default History