import React from "react";
import Layout from "../../components/Layout.tsx";
import Title from "../../components/Title.tsx";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import { LeaderTemplate } from "../../models/LeaderTemplate.ts";
import { notification } from "../../utils/Notifications.tsx";
import { getTemplateLeader } from "../../services/partner.service.ts";
import RowPartners from "../../components/reports/templateLeaders/RowPartners.tsx";
import Divider from "../../components/reports/templateLeaders/Divider.tsx";

const TemplateLeaders = () => {
  useAppSettings();
  const [loading, setLoading] = React.useState(true);
  const [records, setRecords] = React.useState<LeaderTemplate[]>([]);

  const fetchData = async () => {
    try {
      const response = await getTemplateLeader();
      setRecords(response);
    } catch (error) {
      notification("", error?.message, "danger");
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <Layout loading={loading}>
        <Title
          baseTitle="Reportes"
          basePath="/reportes"
          activeTitle="Plantilla de líderes"
          title="Plantilla de líderes"
        />
        <div className="border-0 m-4">
          <div className="row">
            {records.map((record, index) => (
              <div className={`col-md-6 ${index > 1 && "mt-2"}`} key={index}>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">{record.branch.name}</h5>
                    <RowPartners data={record.lideres} title="Líder" />
                    <Divider />
                    <RowPartners
                      data={record.liderServicioMatutino}
                      title="Líder de servicio matutino"
                    />
                    <Divider />
                    <RowPartners
                      data={record.liderServicioVespertino}
                      title="Líder de servicio vespertino"
                    />
                    <Divider />
                    <RowPartners
                      data={record.auxAdministrativoMatutino}
                      title="Auxiliar administrativo matutino"
                    />
                    <Divider />
                    <RowPartners
                      data={record.auxAdministrativoVespertino}
                      title="Auxiliar administrativo vespertino"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default TemplateLeaders;
