import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setQuery } from "../../../../store/slices/chequeoSlice.ts";
import { toCamelCase } from "../../../../hooks/toCameCase.tsx";
import { readBranches } from "../../../../services/branch.service.ts";
import Branch from "../../../../models/Branch.ts";

const FilterChequeo = ({ id }) => {
  const dispatch = useDispatch();
  const initialValues = {
    partnerBranch: "",
    dateStart: "",
    dateEnd: "",
    checkBranch: "",
    shift: "",
    search: "",
  };
  const [timer, setTimer] = React.useState<any>(null);
  const [filters, setFilters] = React.useState(initialValues);
  const [showPills, setShowPills] = React.useState(false);
  const [branches, setBranches] = React.useState<Branch[]>([]);
  const maxFilters = 5;

  const fetchDependencies = async () => {
    const branches = await readBranches();
    setBranches(branches);
  }

  React.useEffect(() => {
    fetchDependencies();
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilters((prevFilters) => ({ ...prevFilters, search: newValue }));
    if (newValue === "") {
      setShowPills(false);
    } else {
      setShowPills(true);
    }
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(
      setTimeout(() => {
        const updatedFilters = { ...filters, search: newValue };
        const queryString = Object.keys(updatedFilters)
          .filter(
            (key) => updatedFilters[key] !== -1 && updatedFilters[key] !== ""
          )
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(
                updatedFilters[key]
              )}`
          )
          .join("&");
        dispatch(setQuery(queryString));
        console.log(queryString);
        setTimer(null);
      }, 500)
    );
  };

  const updateFilters = (params?: {}) => {
    const updatedFilters = { ...filters, ...params };
    const selected = selectedFilters(updatedFilters);

    if (selected.length > maxFilters) {
      alert(`No puedes seleccionar más de ${maxFilters} filtros.`);
      return;
    }

    const queryString = selected
      .map(
        (filter) =>
          `${encodeURIComponent(filter.key)}=${encodeURIComponent(filter.value)}`
      )
      .join("&");

    setFilters(updatedFilters);
    dispatch(setQuery(queryString));
  };

  const selectedFilters = (filters: any) => {
    let selected: any = [];
    for (const key in filters) {
      if (filters[key] !== "all" && filters[key] !== "") {
        let value = filters[key];
        let valueName = "";
        let keyName = "";
        switch (key) {
          case "search":
            keyName = "Búsqueda";
            valueName = value;
            break;
          case "partnerBranch":
            keyName = "Sucursal asignada";
            valueName = branches.find((branch) => branch.id === Number(value))?.name;
            break;
          case "checkBranch":
            keyName = "Sucursal de chequeo";
            valueName = branches.find((branch) => branch.id === Number(value))?.name;
            break;
          case "shift":
            keyName = "Turno";
            valueName = value;
            break;
          case "dateStart":
            keyName = "Fecha inicio";
            valueName = value;
            break;
          case "dateEnd":
            keyName = "Fecha fin";
            valueName = value;
            break;
          default:
            keyName = key;
            valueName = value;
            break;
        }
        selected.push({ key, value, valueName, keyName });
      }
    }

    if (selected.length === 0) setShowPills(false);
    return selected;
  };

  const removeFilter = (key: string) => {
    setFilters({ ...filters, [key]: initialValues[key] });

    const updatedFilters = { ...filters, [key]: initialValues[key] };
    const selected = selectedFilters(updatedFilters);

    const queryString = selected
      .map(
        (filter) =>
          `${encodeURIComponent(filter.key)}=${encodeURIComponent(
            filter.value
          )}`
      )
      .join("&");
    dispatch(setQuery(queryString));
    setShowPills(selected.length > 0);
  };

  useEffect(() => {
    const today = new Date();
    const date = today.toISOString().split("T")[0];

    setFilters({
      ...filters,
      dateStart: date,
    });

    updateFilters({ dateStart: date });
    setShowPills(true);
    
  }, []);

  return (
    <div>
      <div className="input-group mb-2">
        <button
          className="btn btn-white dropdown-toggle"
          type="button"
          data-bs-toggle="dropdown"
        >
          <span className="d-none d-md-inline">Filtrar</span>
          <span className="d-inline d-md-none">
            <i className="fa fa-filter"></i>
          </span>
          <b className="caret"></b>
        </button>
        <div className="dropdown-menu p-4" onClick={(e) => e.stopPropagation()}>
          <label>Sucursal asignada:</label>
          <select
            className="form-select mt-1"
            id="status"
            name="status"
            onChange={(e) => setFilters({ ...filters, partnerBranch: e.target.value })}
            value={filters.partnerBranch}
          >
            <option value={"all"}>Todos</option>
            {branches.map((branch: any) => (
              <option key={branch.id} value={branch.id}>{branch.name}</option>
            ))}
          </select>
          <label>Turno:</label>
          <select
            className="form-select mt-1"
            id="status"
            name="status"
            onChange={(e) => setFilters({ ...filters, shift: e.target.value })}
            value={filters.shift}
          >
            <option value={"all"}>Todos</option>
            <option value={"Matutino"}>Matutino</option>
            <option value={"Intermedio"}>Intermedio</option>
            <option value={"Vespertino"}>Vespertino</option>
          </select>
          {
            !id &&
            <>
              <label>Sucursal de chequeo:</label>
              <select
                className="form-select mt-1"
                id="status"
                name="status"
                onChange={(e) => setFilters({ ...filters, checkBranch: e.target.value })}
                value={filters.checkBranch}
              >
                <option value={"all"}>Todos</option>
                {branches.map((branch: any) => (
                  <option key={branch.id} value={branch.id}>{branch.name}</option>
                ))}
              </select>
            </>
          }
          <label>Fecha inicio:</label>
          <input
            type="date"
            className="form-control"
            onChange={(e) =>
              setFilters({ ...filters, dateStart: e.target.value })
            }
            value={filters.dateStart}
          />
          <label>Fecha fin:</label>
          <input
            type="date"
            className="form-control"
            onChange={(e) =>
              setFilters({ ...filters, dateEnd: e.target.value })
            }
            value={filters.dateEnd}
          />
          <div className="row mt-3 mt-1">
            <div className="col">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setFilters({
                    partnerBranch: "all",
                    dateStart: "",
                    dateEnd: "",
                    checkBranch: "all",
                    shift: "all",
                    search: "",
                  });
                  dispatch(setQuery(""));
                  setShowPills(false);
                }}
                className="btn btn-amarillo d-block w-100 btn-lg fs-14px"
              >
                Limpiar
              </button>
            </div>
            <div className="col">
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  updateFilters();
                  setShowPills(true);
                }}
                className="btn btn-azul d-block w-100 btn-lg fs-14px"
              >
                Aplicar
              </button>
            </div>
          </div>
        </div>

        <div className="flex-fill position-relative">
          <div className="input-group">
            <div
              className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
              style={{ zIndex: 10 }}
            >
              {timer ? (
                <i className="fa fa-spinner fa-spin"></i>
              ) : (
                <i className="fa fa-search opacity-5"></i>
              )}
            </div>
            <input
              type="text"
              className="form-control px-35px bg-light"
              placeholder="Search ..."
              onChange={(e) => handleSearchChange(e)}
              value={filters.search}
            />
          </div>
        </div>
      </div>
      <div className="mb-3">
        {showPills &&
          selectedFilters(filters).map((filter: any) => (
            <div
              key={filter.key}
              className="badge bg-primary text-white fs-6 me-2 position-relative pe-4"
            >
              {toCamelCase(
                filter.keyName === "status" ? "Estatus" : filter.keyName
              )}
              &nbsp;:&nbsp;
              {["false", "true"].indexOf(filter.valueName) > -1
                ? filter.valueName === "true"
                  ? "Activos"
                  : "Inactivos"
                : filter.valueName}
              <button
                type="button"
                className="btn-close btn-close-white position-absolute end-0 top-50 translate-middle"
                aria-label="Close"
                onClick={(e) => {
                  e.stopPropagation();
                  removeFilter(filter.key);
                }}
              ></button>
            </div>
          ))}
      </div>
    </div>
  );
};
export default FilterChequeo;
