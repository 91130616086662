import React from "react";
import { useAppSettings } from "../../hooks/useAppSettings.tsx";
import FiltrosColaboradores from "../../components/colaboradores/FiltrosColaboradores.tsx";
import TableColaboradores from "./TableColaboradores.tsx";
import PaginationColaboradores from "./PaginationColaboradores.tsx";
import { fetchPartners } from "../../services/partner.service.ts";
import { notification } from "../../utils/Notifications.tsx";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/index.ts";
import {
  resetState,
  setColaboradores,
  setDataToRemove,
  setFetch,
  setLoading,
  setMessageModal,
  setModalAcountant,
  setModalCredentials,
  setModalDelete,
  setPage,
  setShow,
  setShowModal,
  setShowModalImportExcel,
  setTotal,
} from "../../store/slices/colaboradoresSlice.ts";
import Title from "../../components/Title.tsx";
import Layout from "../../components/Layout.tsx";
import { readBranches } from "../../services/branch.service.ts";
import ModalContainer from "../../components/ModalContainer.tsx";
import ReactivatePartner from "../../components/talento-humano/ReactivatePartner.tsx";
import GetCredentials from "../../components/colaboradores/GetCredentials.tsx";
import DeletePartner from "../../components/talento-humano/DeletePartner.tsx";
import TableColaboradoresContador from "./TableColaboradoresContador.tsx";
import EditSalary from "../../components/colaboradores/EditSalary.tsx";
import ImportPartners from "../../components/colaboradores/ImportPartners.tsx";
import EditColaboradorMessage from "./EditColaboradorMessage.tsx";
import { useParams } from "react-router-dom";
import { toCamelCase } from "../../hooks/toCameCase.tsx";

const ListColaboradores = () => {
  useAppSettings();
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    menuSelected,
    currentPartner,
    page,
    limit,
    param,
    loading,
    fetch,
    query,
    showModal,
    modalChildren,
    modalTitle,
    show,
    currentUser,
    modalCredentials,
    dataToRemove,
    showDeleteModal,
    showModalImportExcel,
    modalAcountant,
    messageModal,
    sortBy,
    defaultSort,
  } = useSelector((state: RootState) => state.colaboradores);
  const { user } = useSelector((state: RootState) => state.auth);

  const fetchColaboradores = async () => {
    dispatch(setLoading(true));
    try {
      const offset = page === 0 ? 0 : page * limit;
      let queryToSend = query;
      if (queryToSend.length === 0) {
        queryToSend = `tab=${menuSelected}`;
      }

      let filter = `?sort=${sortBy}&order=${defaultSort}&offset=${offset}&limit=${limit}&${queryToSend}`;
      if (user.branch) {
        const branches = await fetchDependencies();
        if (!branches) return;
        const branch = branches.find((b) => b.name === user.branch);

        filter = `?branch=${branch.id}&offset=${offset}&limit=${limit}&${queryToSend}`;
      }
      const response = await fetchPartners(filter);

      dispatch(setColaboradores(response.results));
      dispatch(setTotal(response.total));
    } catch (error) {
      notification("Error", "Error al cargar los colaboradores", "danger");
    } finally {
      dispatch(setLoading(false));
    }
  };

  React.useEffect(() => {
    fetchColaboradores();
  }, [page, limit, menuSelected, param, fetch, query, sortBy, defaultSort]);

  React.useEffect(() => {
    dispatch(resetState());
  }, [id]);

  const fetchDependencies = async () => {
    try {
      const branchsResponse = await readBranches();
      return branchsResponse;
    } catch (error) {
      notification(
        "Error",
        "Ocurrió un error al tratar de obtener los filtros",
        "danger"
      );
    }
  };

  return (
    <Layout loading={loading}>
      <Title
        baseTitle="Colaboradores"
        basePath="/colaboradores"
        title="Colaboradores"
      />
      <div className="card border-0 m-4">
        <div className="tab-content p-3">
          <div className="tab-pane fade show active" id="allTab">
            <FiltrosColaboradores />
            {user.rol === "Contador" ? (
              <TableColaboradoresContador />
            ) : (
              <TableColaboradores />
            )}

            <PaginationColaboradores />
          </div>
        </div>
        <ModalContainer
          open={showModal}
          closeFn={() => dispatch(setShowModal(false))}
          title={modalTitle}
          children={modalChildren}
        />
        <ModalContainer
          open={show}
          closeFn={() => dispatch(setShow(false))}
          title={`Reactivar a ${currentUser?.name}`}
          children={
            <ReactivatePartner
              name={currentUser?.name}
              id={currentUser?.id}
              closeFn={() => dispatch(setShow(false))}
            />
          }
        />
        <ModalContainer
          open={modalCredentials}
          closeFn={() => dispatch(setModalCredentials(false))}
          title={`Credenciales de ${currentUser?.name}`}
          children={
            <GetCredentials
              colaborador={currentUser}
              name={currentUser?.name}
              id={currentUser?.id}
              closeFn={() => dispatch(setModalCredentials(false))}
            />
          }
        />
        <ModalContainer
          open={modalAcountant}
          closeFn={() => dispatch(setModalAcountant(false))}
          title={`Editar salario`}
          children={
            <EditSalary
              colaboradorId={currentUser}
              closeFn={() => dispatch(setModalAcountant(false))}
              refetch={() => {}}
            />
          }
        />
        <ModalContainer
          open={messageModal}
          closeFn={() => dispatch(setMessageModal(false))}
          title={`Mensaje para ${toCamelCase(currentUser?.name)}`}
          children={
            <EditColaboradorMessage
              colaborador={currentUser}
              closeFn={() => dispatch(setMessageModal(false))}
            />
          }
        />
        <ModalContainer
          open={showModalImportExcel}
          closeFn={() => dispatch(setShowModalImportExcel(false))}
          title="Importar colaboradores"
          children={<ImportPartners />}
        />
        <ModalContainer
          open={showDeleteModal}
          closeFn={() => {
            dispatch(setModalDelete(false));
            dispatch(
              setDataToRemove({
                id: -1,
                name: "",
              })
            );
          }}
          title={`Dar de baja a ${toCamelCase(dataToRemove?.name)}`}
          children={
            <DeletePartner
              name={dataToRemove?.name}
              programed={dataToRemove?.programed}
              id={dataToRemove?.id}
              closeFn={() => {
                dispatch(setModalDelete(false));
                dispatch(
                  setDataToRemove({
                    id: -1,
                    name: "",
                  })
                );
                dispatch(setFetch(!fetch));
                dispatch(setPage(0));
              }}
            />
          }
        />
      </div>
    </Layout>
  );
};

export default ListColaboradores;
